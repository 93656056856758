import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/browser';
import App from './components/App/App';
import Firebase, { FirebaseContext } from './services';

Sentry.init({
    dsn: 'https://df06d0ad262d49fb86e130e644ba3f59@sentry.io/1383830',
    environment: process.env.NODE_ENV,
});

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <App />
    </FirebaseContext.Provider>,
    document.getElementById('root'),
);

import React from 'react';

import { APP_TITLE, SKATE_TITLE } from '../../utils/constants';
import Brand from '../Brand/Brand';
import { orgIsTishmanSpeyer, orgIsBottega, orgIs22bg, orgIsSkate } from '../../services/windowLocation';
import ContactInfo from '../ContactInfo/ContactInfo';
import { FirebaseContext } from '../../services';
import Notification from '../Notification/Notification';
import PlaysList from '../PlaysList/PlaysList';
import { Stream } from '../../models';
import StreamsList from '../StreamsList/StreamsList';
import withNotification from '../Notification/withNotification';
import './Console.css';

class Console extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            streams: [],
        };

        this._setStreams = this._setStreams.bind(this);
    }

    componentDidMount() {
        const firebase = this.context;

        firebase.getStreams()
            .then(this._setStreams)
            .catch(this.props.showError);
    }

    _setStreams(querySnapshot) {
        const streams = [];

        querySnapshot.forEach(doc => {
            streams.push(new Stream(doc.id, doc.data()));
        });

        this.setState({
            streams: streams,
        });
    }

    _renderNavigation() {
        const firebase = this.context;

        return (
            <div className="navbar navbar-dark navbar-expand-md bg-dark">
                <div className="container">
                    <h1 className="navbar-brand mb-0" style={{ marginRight: '0' }}>
                        <span>{APP_TITLE} </span>

                        {orgIsTishmanSpeyer() &&
                            <small style={{ marginLeft: "8px" }} className="text-light font-weight-light">
                                Tishman Speyer
                            </small>
                        }
                        {orgIsSkate() &&
                            <small style={{ marginLeft: "8px" }} className="text-light font-weight-light">
                                {SKATE_TITLE}
                            </small>
                        }
                        {orgIsBottega() &&
                            <small style={{ marginLeft: "8px" }} className="text-light font-weight-light">
                                Bottega Veneta
                            </small>
                        }

                        {orgIs22bg() &&
                            <small style={{ marginLeft: "8px" }} className="text-light font-weight-light">
                                22 Bishopsgate
                            </small>
                        }
                    </h1>

                    <button
                        className="navbar-toggler"
                        data-toggle="collapse"
                        data-target="#navbarSignOut">
                        <img src="navbar-toggler-icon.svg" alt="" />
                    </button>

                    <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSignOut">
                        <div className="form-inline mt-2 mt-md-0">
                            <span className="navbar-text text-light font-weight-light mr-3">
                                {firebase.auth.currentUser.email}
                            </span>

                            <button
                                className="btn btn-outline-light"
                                onClick={firebase.doSignOut}>
                                Sign Out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { streams } = this.state;

        return (
            <div>
                <header className="fixed-top">
                    {this._renderNavigation()}

                    <Notification
                        className="p-2 text-center"
                        notification={this.props.notification} />
                </header>

                <main className="signed-in">
                    <PlaysList
                        streams={streams}
                        showError={this.props.showError} />

                    <StreamsList streams={streams} />
                </main>

                <footer className="d-md-flex justify-content-between container pt-4 pb-5 border-top">
                    <ContactInfo className="mb-2 mb-md-0" />

                    <Brand />
                </footer>
            </div>
        );
    }
}

Console.contextType = FirebaseContext;

export default withNotification(Console);

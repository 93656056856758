import React from 'react';

import { PlayIcon, StopIcon, BufferingIcon } from '../Icons/Icons';
import './StreamPreview.css';

const StreamPreview = (props) => {
    const { stream, status, togglePlay } = props;
    const onClick = () => togglePlay(stream.endpoint);

    return(
        <div className="stream-preview lead" onClick={onClick}>
            {{
                playing: <StopIcon
                    className="stream-preview__icon mr-3" />,
                buffering: <BufferingIcon
                    className="stream-preview__icon animate-spin mr-3" />,
                stopped: <PlayIcon
                    className="stream-preview__icon mr-3" />,
            }[status]}

            <span className="align-middle">
                {stream.name}
                <br />
                <small className="text-secondary">{stream.description}</small>
            </span>
        </div>
    );
};

export default StreamPreview;

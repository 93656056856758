import React from 'react';

import StreamPreview from '../StreamPreview/StreamPreview';

class StreamsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sourceRequested: null,
            sourcePlaying: null,
        };

        this.audioElement = React.createRef();

        this._togglePlay = this._togglePlay.bind(this);
        this._renderListItem = this._renderListItem.bind(this);
    }

    _togglePlay(endpoint) {
        const audio = this.audioElement.current;
        const { sourcePlaying, sourceRequested } = this.state;

        if (sourcePlaying || sourceRequested) audio.pause();

        if (sourcePlaying === endpoint || sourceRequested === endpoint) {
            return this.setState({
                sourceRequested: null,
                sourcePlaying: null,
            });
        }

        this.setState({
            sourceRequested: endpoint,
            sourcePlaying: null,
        }, () => {
            audio.play()
                .then(() => {
                    this.setState({
                        sourcePlaying: endpoint,
                    });
                }).catch((err) => {
                    if (err.name === 'AbortError') return;
                    console.log(err);
                });
        });
    }

    _renderListItem(stream, i) {
        const { sourcePlaying, sourceRequested } = this.state;
        let status, playingClass;

        switch (stream.endpoint) {
        case sourcePlaying:
            status = 'playing';
            playingClass = 'bg-light';
            break;
        case sourceRequested:
            status = 'buffering';
            playingClass = 'bg-light';
            break;
        default:
            status = 'stopped';
        }

        return (
            <li className={`list-group-item ${playingClass}`} key={i}>
                <StreamPreview
                    stream={stream}
                    status={status}
                    togglePlay={this._togglePlay} />
            </li>
        );
    }

    render() {
        return (
            <div className="container">
                <h2 className="mt-5 mb-4">Tune In</h2>

                <ul className="list-group">
                    {this.props.streams.map(this._renderListItem)}
                </ul>
                <span className="hidden">
                    <audio
                        controls preload="none" type="audio/mpeg"
                        ref={this.audioElement}
                        src={this.state.sourceRequested} />
                </span>
            </div>
        );
    }
}

export default StreamsList;

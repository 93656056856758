import React from 'react';

import { Building, StatusEvent } from '../../models';
import { FirebaseContext } from '../../services';
import AddLocationModal from '../AddLocationModal/AddLocationModal';
import EditLocationModal from '../EditLocationModal/EditLocationModal';
import PlaySelect from '../PlaySelect/PlaySelect';
import SlotSelect from '../SlotSelect/SlotSelect';
import StatusList from '../StatusList/StatusList';
import StatusBadge from '../StatusList/StatusBadge';
import './PlaysList.css';
import Notification from '../Notification/Notification';
import withNotification from '../Notification/withNotification';

class PlaysList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buildings: [],
            statusEvents: {},
            statusLoadError: false,
            displayAddModal: false,
            displayEditModal: false,
            selectedBuilding: null,
        };

        this._loadStatus = this._loadStatus.bind(this);
        this._renderListItem = this._renderListItem.bind(this);
        this._setBuildings = this._setBuildings.bind(this);
        this._setError = this._setError.bind(this);
        this._setEvents = this._setEvents.bind(this);
        this._toggleAddModal = this._toggleAddModal.bind(this);
        this._toggleEditModal = this._toggleEditModal.bind(this);
    }

    componentDidMount() {
        const firebase = this.context;

        this._unsubscribe = firebase.onBuildingsChange(
            this._setBuildings,
            this.props.showError,
        );
    }

    componentWillUnmount() {
        if (typeof this._unsubscribe === 'function') this._unsubscribe();
    }

    _setBuildings(querySnapshot) {
        const buildings = [];

        querySnapshot.forEach(doc => {
            buildings.push(new Building(doc.id, doc.data()));
        });

        this.setState({
            buildings: buildings,
        });
    }

    _toggleAddModal() {
        this.setState({
            displayAddModal: !this.state.displayAddModal,
        });
    }

    _loadStatus(deviceId) {
        const firebase = this.context;

        firebase.getStatusEvents(deviceId)
            .then(this._setEvents)
            .catch(this._setError);
    }

    _setEvents(querySnapshot) {
        const statusEvents = [];
        let deviceId;
        querySnapshot.forEach(doc => {
            const docData = doc.data();
            deviceId = docData.deviceId;
            statusEvents.push(new StatusEvent(docData));
        });

        this.setState({
            statusEvents: {
                ...this.state.statusEvents,
                [deviceId]: statusEvents,
            },
            statusLoadError: false,
        });
    }

    _setError(e) {
        console.error(e.message);
        this.setState({statusLoadError: true});
    }

    _toggleEditModal(building) {
        this.setState({
            selectedBuilding: this.state.displayEditModal ? null : building,
            displayEditModal: !this.state.displayEditModal,
        });
    }

    _renderListItem(building, i) {
        const { streams } = this.props;

        return (
            <li className="card mb-3" key={i+building.deviceId}>
                <header className="card-header">
                    <h3 className="h5" style={{ display: "inline" }}>
                        {building.name} <em className="small">{building.deviceId}</em> <StatusBadge status={building.status}/>
                    </h3>

                    {this.context.isAdmin() &&
                        <button onClick={() => this._toggleEditModal(building)} className="btn btn-outline-dark" style={{ float: "right" }}>
                            Edit
                        </button>
                    }

                    <ul className="nav nav-tabs card-header-tabs" style={{ marginTop: "10px" }}>
                        <li className="nav-item">
                            <a className="nav-link active"
                                data-toggle="tab"
                                href={`#play-out-${i}`}>
                                Play out
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link"
                                data-toggle="tab"
                                href={`#status-${i}`}
                                onClick={() => this._loadStatus(building.deviceId)}>
                                Status
                            </a>
                        </li>
                    </ul>
                </header>

                <div className="card-body tab-content">
                    <section className="tab-pane active" id={`play-out-${i}`}>
                        {!!building.slots.length && (
                            <SlotSelect
                                streams={streams}
                                building={building}/>
                        )}
                        {!building.slots.length && (
                            <PlaySelect
                                streams={streams}
                                buildingId={building.id} />
                        )}
                    </section>
                    <section className="tab-pane" id={`status-${i}`}>
                        <StatusList
                            statusEvents={this.state.statusEvents[building.deviceId] || []}
                            loadError={this.state.statusLoadError} />
                    </section>
                </div>
            </li>
        );
    }

    render() {
        return (
            <div className="container">
                {this.state.displayAddModal &&
                    <AddLocationModal
                        toggleModal={this._toggleAddModal}
                        showSuccessInParent={this.props.showSuccess}
                        showError={this.props.showError} />
                }

                {this.state.displayEditModal &&
                    <EditLocationModal
                        building={this.state.selectedBuilding}
                        toggleModal={this._toggleEditModal}
                        showSuccessInParent={this.props.showSuccess}
                        showError={this.props.showError} />
                }

                <Notification
                    className="alert alert-success mb-0"
                    notification={this.props.notification} />

                <header className="d-flex justify-content-between align-items-baseline">
                    <h2 className="my-4">Locations</h2>

                    {this.context.isAdmin() &&
                        <button
                            className="btn btn-outline-dark"
                            onClick={this._toggleAddModal}>
                            Add Location
                        </button>
                    }
                </header>

                <ul className="list-unstyled">
                    {this.state.buildings.map(this._renderListItem)}
                </ul>
            </div>
        );
    }
}

PlaysList.contextType = FirebaseContext;

export default withNotification(PlaysList);

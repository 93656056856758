import React from 'react';

import { FirebaseContext } from '../../services';
import Notification from '../Notification/Notification';
import { Play } from '../../models';
import withNotification from '../Notification/withNotification';

class PlaySelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            play: null,
        };

        this._onChange = this._onChange.bind(this);
        this._setPlay = this._setPlay.bind(this);
    }

    componentDidMount() {
        const firebase = this.context;
        const { buildingId, showError } = this.props;

        this._unsubscribe = firebase.onPlaysChange(
            buildingId,
            this._setPlay,
            showError,
        );
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    _setPlay(querySnapshot) {
        let play;

        querySnapshot.forEach(doc => {
            play = new Play(doc.data());
        });

        this.setState({
            play: play,
        });
    }

    _onChange(event) {
        const firebase = this.context;
        const { buildingId, showError, showSuccess } = this.props;

        // Firebase Web client library does not support custom objects instantiated with new...()
        // Therefore we have to recreate the Play object structure manually
        firebase.plays()
            .add({
                buildingId: buildingId,
                startTime: firebase.timestamp(),
                streamId: event.target.value,
            })
            .then(() => {
                showSuccess('Updated', 2000);
            })
            .catch(() => {
                showError('Something went wrong', 4000);
            });
    }

    render() {
        const { buildingId, streams, notification } = this.props;
        const { play } = this.state;
        const id = `${buildingId}-select`;
        const value = play ? play.streamId : '';

        return (
            <div>
                <Notification className="badge" notification={notification} />

                <select
                    className="custom-select"
                    id={id}
                    value={value}
                    onChange={this._onChange}>
                    <option value="">None</option>

                    {streams.map((stream, i) =>
                        <option value={stream.id} key={i}>{stream.name}</option>,
                    )}
                </select>
            </div>
        );
    }
}

PlaySelect.contextType = FirebaseContext;

export default withNotification(PlaySelect);

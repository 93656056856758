import { CONTACT_EMAIL, CONTACT_EMAIL_TS } from '../utils/constants';

const ORG_IDS = {
    tishmanSpeyer: 'tishmanspeyer',
    modes: 'modes',
    bottega: 'bottega',
    '22bg': '22bg',
    skate: 'skate',
};

function hostnameHas(name) {
    return window.location.hostname.indexOf(name) > -1;
}

function orgId() {
    if (hostnameHas('tishmanspeyer')) return ORG_IDS.tishmanSpeyer;
    if (hostnameHas('modes')) return ORG_IDS.modes;
    if (hostnameHas('bottega')) return ORG_IDS.bottega;
    if (hostnameHas('22bg')) return ORG_IDS['22bg'];
    if (hostnameHas('skate')) return ORG_IDS.skate;
    return '';
}

function contactEmail() {
    if (orgIsTishmanSpeyer() || orgIsSkate()) return CONTACT_EMAIL_TS;

    return CONTACT_EMAIL;
}

function orgIsTishmanSpeyer() {
    return orgId() === ORG_IDS.tishmanSpeyer;
}

function orgIsBottega() {
    return orgId() === ORG_IDS.bottega;
}

function orgIs22bg() {
    return orgId() === ORG_IDS['22bg'];
}

function orgIsSkate() {
    return orgId() === ORG_IDS.skate;
}

export {
    contactEmail,
    orgId,
    orgIs22bg,
    orgIsBottega,
    orgIsSkate,
    orgIsTishmanSpeyer,
};

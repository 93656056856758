import React from 'react';

import Console from '../Console/Console';
import { FirebaseContext } from '../../services';
import SignInForm from '../SignInForm/SignInForm';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };
    }

    componentDidMount() {
        const firebase = this.context;

        this._unsubscribe = firebase.auth
            .onAuthStateChanged(user => {
                this.setState({
                    user: user || null,
                });
            });
    }

    componentWillUnmount() {
        this._unsubscribe();
    }

    render() {
        if (this.state.user) return <Console />;

        return <SignInForm />;
    }
}

App.contextType = FirebaseContext;

export default App;

import React from 'react';

const Brand = () => (
    <div>
        <a href="http://nts.live" target="_blank" rel="noopener noreferrer">
            <span className="text-muted mr-2">Powered by</span>

            <img src="nts-logo.svg" alt="NTS logo" width="24px" />
        </a>
    </div>
);

export default Brand;

import React from 'react';

import { contactEmail } from '../../services/windowLocation';
import './ContactInfo.css';

const ContactInfo = (props) => (
    <div {...props}>
        <span className="text-muted">Need help? </span>

        <a
            className="mailto-link font-weight-bold"
            href={`mailto: ${contactEmail()}`}>
            {contactEmail()}
        </a>
    </div>
);

export default ContactInfo;

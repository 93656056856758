import React from 'react';

import { FirebaseContext } from '../../services';
import Notification from '../Notification/Notification';
import withNotification from '../Notification/withNotification';

class AddLocationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            deviceId: '',
        };

        this._handleChange = this._handleChange.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current.focus();
    }

    _handleChange(event) {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    }

    _handleSubmit(event) {
        const name = this.state.name.trim();
        const deviceId = this.state.deviceId.trim().toLowerCase();

        this.context.addBuilding(name, deviceId)
            .then(() => {
                this.props.showSuccessInParent(`Added ${name} (${deviceId})`);
                this.props.toggleModal();
            })
            .catch(() => this.props.showError());

        event.preventDefault();
    }

    render() {
        const formIsComplete = this.state.name.length
            && this.state.deviceId.length;

        return (
            <div
                style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
                className="modal"
                tabIndex="-1">

                <div className="modal-dialog modal-dialog-centered">
                    <form
                        className="modal-content"
                        onSubmit={this._handleSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Add Location
                            </h5>

                            <button
                                onClick={this.props.toggleModal}
                                className="close">
                                <span aria-hidden="true">
                                    &times;
                                </span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="location-name">
                                    Location Name
                                </label>

                                <input
                                    ref={this.inputRef}
                                    className="form-control"
                                    type="text"
                                    id="location-name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this._handleChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="player-id">
                                    Player ID
                                </label>

                                <input
                                    className="form-control"
                                    type="text"
                                    id="player-id"
                                    name="deviceId"
                                    value={this.state.deviceId}
                                    onChange={this._handleChange} />

                                <small className="form-text text-muted">
                                    You can find the Player ID printed on the underside of the playback device.
                                </small>
                            </div>

                            <Notification
                                className="alert mb-0"
                                notification={this.props.notification} />
                        </div>

                        <div className="modal-footer">
                            <button
                                onClick={this.props.toggleModal}
                                className="btn btn-secondary">
                                Cancel
                            </button>

                            <button
                                disabled={!formIsComplete}
                                type="submit"
                                value="submit"
                                className="btn btn-primary">
                                Add Location
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

AddLocationModal.contextType = FirebaseContext;

export default withNotification(AddLocationModal);

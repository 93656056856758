import React from 'react';

import { FirebaseContext } from '../../services';
import Notification from '../Notification/Notification';
import withNotification from '../Notification/withNotification';

class SlotSelect extends React.Component {
    constructor() {
        super();

        this._onChange = this._onChange.bind(this);
        this._renderListItem = this._renderListItem.bind(this);
    }

    _onChange(event) {
        const firebase = this.context;
        const { building, showError, showSuccess } = this.props;
        const { id, slots } = building;
        const { startTime } = event.target.dataset;
        const newStreamId = event.target.value;

        const updatedSlots = slots.map(slot => {
            const streamId = slot.startTime === startTime ? newStreamId : slot.streamId;
            return {
                streamId,
                startTime: slot.startTime,
            };
        });

        firebase.editBuilding(id, { slots: updatedSlots })
            .then(() => showSuccess('Updated', 2000))
            .catch(() => showError('Something went wrong', 4000));
    }

    _renderListItem(slot, i) {
        const { building, streams } = this.props;
        const { streamId, startTime, endTime } = slot;
        const id = `${building.id}-select`;

        return (
            <div style={{ marginBottom: "10px" }}
                className="d-flex justify-content-between align-items-baseline">
                <div style={{ marginRight: "34px", whiteSpace: "nowrap" }}>
                    {startTime} - {endTime}
                </div>
                <select
                    className="custom-select"
                    id={id}
                    key={i}
                    data-start-time={startTime}
                    value={streamId}
                    onChange={this._onChange}>
                    <option value="">None</option>

                    {streams.map((stream, i) =>
                        <option value={stream.id} key={i}>{stream.name}</option>,
                    )}
                </select>
            </div>
        );
    }

    render() {
        const { notification, building } = this.props;
        const { slots, timezone } = building;

        return (
            <div>
                <Notification className="badge" notification={notification} />

                {slots.map((slot, i) => this._renderListItem(slot, i))}

                <div style={{ marginTop: "20px" }}>
                    <small className="text-secondary">
                        {timezone ? `Timezone: ${timezone}` : ''}
                    </small>
                </div>
            </div>
        );
    }
}

SlotSelect.contextType = FirebaseContext;

export default withNotification(SlotSelect);

import React from 'react';

import { APP_TITLE, SKATE_TITLE } from '../../utils/constants';
import Brand from '../Brand/Brand';
import { orgIsTishmanSpeyer, orgIsBottega, orgIsSkate } from '../../services/windowLocation';
import ContactInfo from '../ContactInfo/ContactInfo';
import { FirebaseContext } from '../../services';
import Notification from '../Notification/Notification';
import { Stream } from '../../models';
import StreamsList from '../StreamsList/StreamsList';
import withNotification from '../Notification/withNotification';
import './SignInForm.css';

const INITIAL_STATE = {
    email: '',
    password: '',
    streams: [],
};

class SignInForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };

        this._onSubmit = this._onSubmit.bind(this);
        this._onChange = this._onChange.bind(this);
    }

    componentDidMount() {
        const allowPublicTuneIn = window.location.search.indexOf('tune') !== -1;
        if (!allowPublicTuneIn || !orgIsTishmanSpeyer()) return;

        const firebase = this.context;
        firebase.getTsPreferredStream()
            .then(doc => {
                this.setState({
                    streams: [new Stream(doc.id, doc.data())],
                });
            });
    }

    _onSubmit(event) {
        const firebase = this.context;
        const { email, password } = this.state;
        const { clearNotification, showError } = this.props;

        firebase.doSignIn(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                if (this.props.notification) clearNotification();
            })
            .catch(error => {
                showError(error.message);
            });

        event.preventDefault();
    }

    _onChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    render() {
        const { email, password } = this.state;

        const isInvalid = password === '' || email === '';
        const formTitle = orgIsSkate() ? SKATE_TITLE : APP_TITLE;

        return (
            <main className="signed-out d-flex flex-column justify-content-around align-items-center">
                <form
                    className="form-signin p-3 text-center"
                    onSubmit={this._onSubmit}>

                    {orgIsTishmanSpeyer() &&
                        <React.Fragment>
                            <img
                                src="TS_Logo_6line_pos_Tishman_Speyer_Logo.jpg"
                                alt="Tishman Speyer logo"
                                width="200px" />

                            <hr width="32px" />
                        </React.Fragment>
                    }

                    {orgIsBottega() &&
                        <React.Fragment>
                            <img
                                src="Bottega_Logo_Dashboard.jpg"
                                alt="Bottega Veneta logo"
                                width="200px" />

                            <hr width="32px" />
                        </React.Fragment>
                    }

                    <div>
                        <h1 className="h3 mb-4">{formTitle}</h1>

                        <input
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={this._onChange}
                            type="email"
                            placeholder="Email Address"
                            autoComplete="email"
                        />

                        <input
                            className="form-control mb-3"
                            name="password"
                            value={password}
                            onChange={this._onChange}
                            type="password"
                            placeholder="Password"
                            autoComplete="current-password"
                        />

                        <button
                            className="btn btn-lg btn-dark btn-block mb-4"
                            disabled={isInvalid}
                            type="submit">
                            Sign In
                        </button>

                    </div>

                    <span className="text-muted">Internet Explorer not supported. </span>
                    <ContactInfo className="mb-4" />

                    <div className="signin-error">
                        <Notification
                            className="alert mb-0"
                            notification={this.props.notification} />
                    </div>
                </form>

                { !!this.state.streams.length && <StreamsList streams={this.state.streams} /> }

                <Brand />
            </main>
        );
    }
}

SignInForm.contextType = FirebaseContext;

export default withNotification(SignInForm);

// table: buildings
class Building {
    constructor(docId, buildingData) {
        this.id = docId;
        this.name = buildingData.name;
        this.deviceId = buildingData.deviceId;
        this.slots = parseSlots(buildingData.slots);
        this.status = buildingData.status;
        this.timezone = buildingData.timezone || null;
    }
}

// table: statusEvents
class StatusEvent {
    constructor(docData) {
        this.status = docData.status;
        this.updateTime = docData.updateTime.toDate();
    }
}

// table: streams
class Stream {
    constructor(docId, streamData) {
        this.id = docId;
        this.endpoint = streamData.endpoint.replace('http:', 'https:');
        this.name = streamData.name;
        this.description = streamData.description;
    }
}

// table: plays
class Play {
    constructor(playData) {
        this.startTime = playData.startTime;
        this.buildingId = playData.buildingId;
        this.streamId = playData.streamId;
    }
}

class SuccessNotification {
    constructor(message, timeToShow) {
        this.show = true;
        this.error = false;
        this.message = message;
        this.timeToShow = timeToShow;
    }
}

class ErrorNotification extends SuccessNotification {
    constructor(message, timeToShow) {
        super(message, timeToShow);
        this.error = true;
    }
}

function parseSlots(slots) {
    if (!slots) return [];

    return slots
        .sort((a, b) => {
            if (a.startTime < b.startTime) return -1;
            if (a.startTime > b.startTime) return 1;
            return 0;
        })
        .map((slot, i) => new Slot(slot, i, slots));
}

class Slot {
    constructor(currentSlot, i, slots) {
        this.startTime = currentSlot.startTime;
        this.streamId = currentSlot.streamId;
        this.endTime = this._findSlotEndTime(i, slots);
    }

    _findSlotEndTime(i, slots) {
        return slots[i + 1] ? slots[i + 1].startTime : slots[0].startTime;
    }
}

export {
    Building,
    StatusEvent,
    Stream,
    Play,
    SuccessNotification,
    ErrorNotification,
};

import React from 'react';

import { SuccessNotification, ErrorNotification } from '../../models';

function withNotification(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                notification: null,
            };

            this.set = this.set.bind(this);
            this.clear = this.clear.bind(this);
            this.showError = this.showError.bind(this);
            this.showSuccess = this.showSuccess.bind(this);
        }

        clear() {
            this.setState(state => {
                return {
                    notification: {
                        ...state.notification,
                        show: false,
                    },
                };
            });
        }

        set(notification) {
            const timeToShow = notification.timeToShow;
            const callback = timeToShow
                ? () => {
                    this.timeoutId = window.setTimeout(this.clear, timeToShow);
                } : null;

            window.clearTimeout(this.timeoutId);

            this.setState({
                notification: notification,
            }, callback);
        }

        showError(message, timeToShow) {
            this.set(new ErrorNotification(message, timeToShow));
        }

        showSuccess(message, timeToShow) {
            this.set(new SuccessNotification(message, timeToShow));
        }

        render() {
            return <WrappedComponent
                showError={this.showError}
                showSuccess={this.showSuccess}
                clearNotification={this.clear}
                notification={this.state.notification}
                {...this.props} />;
        }
    };
}

export default withNotification;

import React from 'react';

import { contactEmail } from '../../services/windowLocation';

const Notification = ({ className, notification }) => {
    if (!notification) return null;

    const showClass = notification.show ? 'show' : '';
    const errorClass = notification.error ? 'alert-danger' : 'alert-success';
    const defaultMessage = `Something went wrong. Contact ${contactEmail()} for help.`;

    return (
        <div className={`${className} ${errorClass} ${showClass} fade`}>
            {notification.message ? notification.message.toString() : defaultMessage}
        </div>
    );
};

export default Notification;

import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { orgId } from '../services/windowLocation';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();

        this.doSignOut = this.doSignOut.bind(this);
    }

    doSignIn(email, password) {
        return this.auth.signInWithEmailAndPassword(email, password);
    }

    doSignOut() {
        return this.auth.signOut();
    }

    isAdmin() {
        return this.auth.currentUser && this.auth.currentUser.email.includes("@ntslive.co.uk");
    }

    buildings() {
        return this.db.collection('buildings');
    }

    getBuildings() {
        const query = this.buildings()
            .where('orgId', '==', orgId())
            .orderBy('name');

        return query.get();
    }

    onBuildingsChange(callback, handleError) {
        const query = this.buildings()
            .where('orgId', '==', orgId())
            .orderBy('name');

        return query.onSnapshot(
            callback,
            firebaseError => handleError(new Error(firebaseError.message)),
        );
    }

    addBuilding(name, deviceId) {
        return this.buildings().add({
            name: name,
            deviceId: deviceId,
            orgId: orgId(),
        });
    }

    editBuilding(docId, data) {
        return this.buildings().doc(docId).set(data, { merge: true });
    }

    getStreams() {
        const query = this.db.collection('streams')
            .where('orgId', '==', orgId())
            .orderBy('order');

        return query.get();
    }

    getStatusEvents(deviceId) {
        const query = this.db.collection('statusEvents')
            .where('deviceId', '==', deviceId)
            .orderBy('updateTime', 'desc')
            .limit(5);

        return query.get();
    }


    getTsPreferredStream() {
        return this.db.collection('streams')
            .doc('biz1')
            .get();
    }

    plays() {
        return this.db.collection('plays');
    }

    onPlaysChange(buildingId, callback, handleError) {
        const query = this.plays()
            .where('buildingId', '==', buildingId)
            .orderBy('startTime', 'desc')
            .limit(1);

        return query.onSnapshot(
            callback,
            firebaseError => handleError(new Error(firebaseError.message)),
        );
    }

    timestamp() {
        return app.firestore.FieldValue.serverTimestamp();
    }
}

export default Firebase;

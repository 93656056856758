import React from 'react';

class StatusBadge extends React.Component {
    render() {
        let badgeClass;
        switch (this.props.status) {
        case "online":
            badgeClass = 'badge-success';
            break;
        case "offline":
            badgeClass = 'badge-warning';
            break;
        default:
            badgeClass = 'badge-secondary';
        }

        return (<span className={`badge ${badgeClass}`} style={{minWidth: '50px'}}>{this.props.status || 'unknown'}</span>);
    }
}

export default StatusBadge;

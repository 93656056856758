import React from 'react';
import StatusBadge from './StatusBadge';

class StatusList extends React.Component {

    render() {
        if (!this.props.statusEvents.length) {
            return <p className="m-0">No status events are available yet.</p>;
        }

        if (this.props.loadError) {
            return <p className="m-0">Failed to load player status.</p>;
        }

        return (
            <div className="list-group">
                {this.props.statusEvents.map((statusEvent, i) => {
                    const key = [i, statusEvent.updateTime.getTime()].join('-');
                    return (
                        <p key={key} >
                            <StatusBadge status={statusEvent.status}/> {`since ${statusEvent.updateTime.toLocaleString()}`}
                        </p>
                    );
                })}
            </div>
        );
    }
}

export default StatusList;
